<!-- 相关协议 -->
<template>
	<div class="o_container">
		<div v-if="status" class="o_title">相关协议</div>
		<div v-else class="o_title">
			<div class="o_title_back" @click="showList">
				<span class="iconfont icon-a-fundmarkect-back"></span>
				<span>协议详情</span>
			</div>
		</div>
		<list v-if="status" @toDetail="toDetail"></list>
		<detail v-else :baseInfo="baseInfo"></detail>
	</div>
</template>

<script>
	import list from './components/agreementList.vue'
	import detail from './components/agreementDetail.vue'
	export default{
		components:{
			list,
			detail
		},
		data(){
			return{
				status:true,
				baseInfo:{}
			}
		},
		created(){
		},
		methods:{
			toDetail(item){
				this.baseInfo = item;
				this.status = false;
			},
			showList(){
				this.status = true;
			}
		}
	}
</script>

<style scoped>
	.o_container{
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 40px;
		padding: 40px;
		width: 930px;
		background-color: #fff;
		box-sizing: border-box;
		min-height: 1200px;
	}
	.o_title{
		display: flex;
	}
	.o_title_back{
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.icon-a-fundmarkect-back{
		margin-top: 2px;
		color: #bdc0cb;
		margin-right: 10px;
	}
</style>