<!-- 协议详情 -->
<template>
	<div class="o_detail">
		<div>《平台协议》</div>
		<div class="o_detail_content">
			协议内容
		</div>
	</div>
</template>
<script>
	export default{
		props:{
			baseInfo:{
				type:Object,
				default:()=>{}
			}
		},
		data(){
			return{}
		},
		created(){
			console.log(this.baseInfo)
		}
	}
</script>
<style scoped>
	.o_detail{
		padding-top: 50px;
	}
	.o_detail_content{
		padding-top: 20px;
		font-size: 14px;
	}
</style>