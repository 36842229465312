<!-- 协议列表 -->
<template>
	<div class="o_list">
		<div class="o_list_item" v-for="item in list" :key="item.showText" @click="toDetail(item)">
			<span>{{item.showText}}</span>
			<span class="iconfont icon-simu-steps2"></span>
		</div>
	</div>
</template>
<script>
	import { protPage } from '@/api/other/index.js'
	export default{
		data(){
			return{
				list:[]
			}
		},
		created(){
			this.getDicts('protocol_name').then(res => {
				let data1 = res.data.filter(m => m.dictLabel == '《众惠基金隐私保护指引》' || m.dictLabel == '《投资者权益需知》'|| m.dictLabel == '《用户服务协议》')
				protPage({protType:'1',status:'1',pageSize:100,pageNum:1}).then(r=>{
					let data2 = r.data.data
          let datas = []
          data1.forEach(m => {
            let data = data2.find(n => m.dictValue == n.protName)
            if(data) {
              data.showText = m.dictLabel
              datas.push(data)
            }
          })
					this.list = datas
				})
			})
		},
		methods:{
			toDetail(item){
				// this.$emit('toDetail',item)
				item.protUrl&&item.protUrl.length>0?this.toPdf(item.protUrl):this.treatyerr()
				// window.open(item.protUrl)
			}
		}
	}
</script>
<style scoped>
	.o_list{
		margin-top: 54px;
	}
	.o_list_item{
		height: 94px;
		padding: 0 19px;
		font-size: 18px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #ffffff;
		box-shadow: 0px 4px 12px 0px #edf1f7; 
		margin-bottom: 30px;
	}
	.icon-simu-steps2{
		color: #e0e2e8;
		font-size: 20px;
	}
</style>